$(document).ready(function() {
    var $collection = $('.js-collection');

    if (0 === $collection.length) {
        return;
    }

    var $collectionDataList = $collection.find('.js-collection-data-list');
    var $collectionAddContainer = $collection.find('.js-collection-add-container');
    var $collectionAdd = $collectionAddContainer.find('.js-collection-add');
    var $collectionTemplate = $collection.find('.js-collection-widget-block-template');
    var collectionDataWidgetTag = $collectionDataList.attr('data-widget-tags');
    var collectionCreatorId = $collection.attr('data-creator-id');

    var collectionFormatMember  = function(member) {
        if (member.email != undefined) {
            var markup = "<div class='select2-result-member'>" +
                "<div class='select2-result-member__title'>" + member.firstname + ' ' + member.lastname + "</div>" +
                "<div style='font-size: 12px'>(" + member.email +")" +
                "</div>";

            return markup;
        }
    };

    var collectionFormatMemberSelection = function(member) {
        if (member.firstname != undefined) {
            return member.firstname + ' ' + member.lastname;
        }

        return member.text;
    };

    var sessionId;

    if ($collection[0].hasAttribute('data-session-id')) {
        sessionId = $collection.attr('data-session-id');
    }

    var collectionSelect2Parameters = {
        dropdownAutoWidth: true,
        width: 'style',
        ajax: {
            url: Routing.generate('search_coaches', {'creator': collectionCreatorId}),
            cache: true,
            dataType: 'json',
            delay: 250,
            placeholder: '',
            data: function (params) {
                if ('undefined' !== typeof sessionId) {
                    return {
                        terms: params.term, // search term
                        session_id: sessionId
                    };
                }

                return {terms: params.term};
            },
            processResults: function (data) {
                return {
                    results: data
                };
            }
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        minimumInputLength: 2,
        templateResult: collectionFormatMember,
        templateSelection: collectionFormatMemberSelection
    };

    var $collectionWidgetBlocks = $collection.find('.js-collection-widget-block');

    $collectionWidgetBlocks.each(function() {
        var $collectionWidgetBlock = $(this);
        var $collectionInput = $collectionWidgetBlock.find('.js-collection-input');

        var collectionSelect2Element = $collectionInput.select2(collectionSelect2Parameters);

        if ($collectionInput[0].hasAttribute('data-current-coach-id')) {
            var currentCoachId = $collectionInput.attr('data-current-coach-id');

            collectionSelect2Element.val(currentCoachId).trigger('change');
        }

        var $removeButton = $collectionWidgetBlock.find('.js-collection-remove');

        $removeButton.click(function(e) {
            e.preventDefault();

            $collectionWidgetBlock.remove();

            var oldCounter = $collectionDataList.data('widget-counter');

            $collectionDataList.data('widget-counter', oldCounter - 1);

            //this line is specific to single-element collections
            $collectionAddContainer.removeClass('hidden');
        });
    });

    $collectionAdd.click(function (e) {
        e.preventDefault();

        $collectionAddContainer.addClass('hidden');

        if (0 === $collection.find('.js-collection-input').length) {
            return;
        }

        var counter;

        if ($collectionDataList[0].hasAttribute('widget-counter')) {
            counter = $collectionDataList.data('widget-counter')
        } else {
            counter = $collectionDataList.children().length;
        }

        var $widgetBlock = $collectionTemplate
            .clone(true)
            .removeClass('hidden')
            .removeClass('js-collection-widget-block-template')
        ;

        var select2Inputs = [];

        $('input, select', $widgetBlock).each(function () {
            $(this).attr('name', $(this).attr('data-name').replace(/__name__/g, counter));
            $(this).removeAttr('data-name');

            $(this).attr('id', $(this).attr('data-id').replace(/__name__/g, counter));
            $(this).removeAttr('data-id');

            var $relatedLabel = $(this).prev($('label'));

            if (0 < $relatedLabel.length) {
                $relatedLabel.attr('for', $(this).attr('id'));
            }

            if ($(this)[0].hasAttribute('data-toggle') && 'select2' === $(this).attr('data-toggle')) {
                select2Inputs.push($(this));
            }
        });

        $collectionDataList.data('widget-counter', counter);

        var $newListCollectionWidget = $(collectionDataWidgetTag).html($widgetBlock).addClass('js-collection-widget-block');
        $newListCollectionWidget.appendTo($collectionDataList);

        for (var i = 0; i < select2Inputs.length; i++) {
            select2Inputs[i].select2(collectionSelect2Parameters);
        }

        var $removeButton = $widgetBlock.find('.js-collection-remove');

        $removeButton.click(function(e) {
            e.preventDefault();

            $(this).closest('.js-collection-widget-block').remove();

            var oldCounter = $collectionDataList.data('widget-counter');

            $collectionDataList.data('widget-counter', oldCounter - 1);

            //this line is specific to single-element collections
            $collectionAddContainer.removeClass('hidden');
        });

        // Increase the counter
        counter++;
    });
});
