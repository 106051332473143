$(document).ready(function() {
    var $simpleTeamSelection = $('.js-simple-team-selection');

    if (0 === $simpleTeamSelection.length) {
        return
    }

    var $simpleTeamSelectionInput = $simpleTeamSelection.find('.js-simple-team-input');

    var formatTeam = function (team) {
        return team.name;
    };

    var formatTeamSelection = function (team) {
        return team.text;
    };

    var select2Options = {
        language: {
            noResults: function() {
                return '';
            }
        },
        allowClear: true,
        dropdownAutoWidth: true,
        positionDropdown: true,
        multiple: false,
        tags: false,
        width: "style",
        ajax: {
            url: Routing.generate('team_search_teams'),
            cache: true,
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    query: params.term // search term
                };
            },
            processResults: function (data) {
                return {
                    results: data
                };
            }
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        minimumInputLength: 2,
        templateResult: formatTeam,
        templateSelection: formatTeamSelection
    };

    var teamSelector = $simpleTeamSelectionInput.select2(select2Options);

    if ($simpleTeamSelection[0].hasAttribute('data-team-id')) {
        var dataCurrentTeamId = $simpleTeamSelection.attr('data-team-id');
        teamSelector.val(dataCurrentTeamId).trigger('change');
    }
});
