$(document).ready(function () {
    $profilePassword = $('.js-profile-password');

    if (0 === $profilePassword.length) {
        return;
    }

    $profilePasswordTrigger = $profilePassword.find('.js-profile-password-recovery-trigger');
    $profilePasswordTarget = $profilePassword.find('.js-profile-password-recovery-target');
    $profilePasswordEmail = $profilePasswordTarget.find('.js-profile-password-recovery-email');
    $profilePasswordGetPassword = $profilePasswordTarget.find('.js-profile-password-get-password');

    $profilePasswordFlashSuccess = $profilePassword.find('.js-profile-password-flash-success');
    $profilePasswordFlashFailure = $profilePassword.find('.js-profile-password-flash-failure');
    $profilePasswordFlashFailurePopupContent = $profilePasswordFlashFailure.find('.js-profile-password-flash-popup-content');

    $profilePasswordFlashBackground = $profilePassword.find('.js-profile-password-flash-background');

    var closeFlashPopup = function() {
        $profilePasswordTarget.addClass('hidden');
        $profilePasswordFlashSuccess.addClass('hidden');
        $profilePasswordFlashFailure.addClass('hidden');
        $profilePasswordFlashBackground.addClass('hidden');
    };

    $(document).keyup(function (event) {
        if (event.which == '27') {
            closeFlashPopup();
        }
    });

    $profilePassword.on('click', '.js-profile-password-flash-close', function () {
        closeFlashPopup();
    });

    $profilePasswordClose = $profilePasswordTarget.find('.js-profile-password-close');

    $profilePasswordTrigger.click(function() {
        $profilePasswordTarget.removeClass('hidden');
        $profilePasswordFlashBackground.removeClass('hidden');
    });

    $profilePasswordClose.click(function() {
        $profilePasswordTarget.addClass('hidden');
        $profilePasswordFlashBackground.addClass('hidden');
    });

    $profilePasswordGetPassword.click(function(e) {
        e.preventDefault();

        var email = $profilePasswordEmail.attr('data-recovery-email');
        var csrfToken = $profilePasswordTarget.find("input[name='_csrf_token']").val();

        $.ajax({
            type: 'POST',
            url: Routing.generate('member_send_password_recovery_from_profile'),
            data: {username: email, _csrf_token: csrfToken},
            success: function(data) {
                if (data['success']) {
                    $profilePasswordFlashSuccess.removeClass('hidden');
                    $profilePasswordFlashBackground.removeClass('hidden');

                    $profilePasswordTarget.addClass('hidden');
                } else {
                    if ('not_found' === data['failure_reason']) {
                        $profilePasswordFlashFailurePopupContent.text('Nous n\'avons pas retrouvé ton compte.');
                    } else if ('password_request_not_expired' === data['failure_reason']) {
                        $profilePasswordFlashFailurePopupContent.text('Vérifie tes mails. Nous t\'avons déjà envoyé un lien pour réinitialiser ton mot de passe avec l\'adresse "bienvenue@allsessions.club". Si tu ne retrouves plus le mail, n\'hésite pas à nous contacter sur "contact@allsessions.club".');
                    } else {
                        $profilePasswordFlashFailurePopupContent.text('Une erreur s\'est produite, peux-tu rafraîchir la page et réessayer ?');
                    }

                    $profilePasswordFlashFailure.removeClass('hidden');
                    $profilePasswordFlashBackground.removeClass('hidden');
                    $profilePasswordTarget.addClass('hidden');
                }
            }
        });
    });

    $profilePasswordTrigger.click(function(e) {
        e.preventDefault();
        $profilePasswordTarget.removeClass('hidden');
    });
});
