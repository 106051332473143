$(document).ready(function() {
    var $creditAdminTeamPotentiallyCreditedMembersList = $('.js-credit-potentially-credited-members-list');

    if (0 === $creditAdminTeamPotentiallyCreditedMembersList.length) {
        return;
    }

    var creditTeamCheckedMembers = $creditAdminTeamPotentiallyCreditedMembersList.attr('data-member-ids').split('-');

    function constructMemberBlock(memberData) {
        var $memberBlock = $('.js-credit-team-member-block-template')
            .clone(true)
            .removeClass('hidden')
            .removeClass('js-credit-team-member-block-template')
        ;

        if (null !== memberData.id) {
            $memberBlock.find('.js-credit-team-member-block-firstname').text(memberData.firstname);
            $memberBlock.find('.js-credit-team-member-block-lastname').text(memberData.lastname);
            $memberBlock.find('.js-credit-team-member-block-picture').attr('src', memberData.picture_browser_path);

            var $checkboxInput = $memberBlock.find('.js-credit-team-member-block-checkbox-input');
            $checkboxInput.attr('id', 'allsessions_credit_team_memberIds_' + memberData.id);
            $checkboxInput.attr('value', memberData.id);

            if (creditTeamCheckedMembers && creditTeamCheckedMembers.hasOwnProperty(memberData.id)) {
                $checkboxInput.prop('checked', true);
            }

            if ($('#allsessions_credit_team_members_memberIds_' + memberData.id).length) {
                $checkboxInput.prop('checked', true);
            }
        }

        return $memberBlock;
    }

    function fillPotentiallyCreditedMembers(data) {
        var $potentiallyCreditedList = $('.js-credit-potentially-credited-members-list');

        $potentiallyCreditedList.hide(function () {
            $potentiallyCreditedList.empty();

            if (!data.hasOwnProperty('no_members_message')) {
                    data.members_data.forEach(function (memberData) {
                    var $memberBlock = constructMemberBlock(memberData);
                    $potentiallyCreditedList.append($memberBlock);
                });

                $('.js-credit-checkbox-all').prop('checked', false);
                $('.js-credit-checkbox-all').removeClass('hidden');
            } else {
                $('.js-credit-checkbox-all').addClass('hidden');
                $potentiallyCreditedList.parent().find('.js-credit-team-no-found-members').text(data.no_members_message);
            }

            $potentiallyCreditedList.show();
        });
    }

    var creditTeamMembersCheckAllState = false;
    var $creditMembersList = $('#allsessions_credit_team_members_memberIds');
    var $creditAdminTeamForm = $('.js-credit-admin-team-members-form');
    var $creditAdminTeamFormAmount = $('.js-credit-admin-team-amount');

    function updatePotentiallyCreditedMembersCount() {
        var $counterElement = $('.js-credit-potentially-credited-members-counter');
        var count = $('#allsessions_credit_team_members_memberIds > input[type="hidden"]').length;

        $counterElement.children().addClass("hidden");

        if (count == 0) {
            $counterElement.find('.no-member-selected').removeClass("hidden");
        } else if (count == 1) {
            $counterElement.find('.one-member-selected').removeClass("hidden");
        } else if (count > 1) {
            $counterElement.find('.js-counter-value').text(count);
            $counterElement.find('.js-counter-value').removeClass("hidden");
            $counterElement.find('.several-members-selected').removeClass('hidden');
        }
    }

    function setupPagination(data) {
        var $potentiallyCreditedListPagination = $('.js-credit-potentially-credited-members-list-pagination');

        if (!data.hasOwnProperty('no_members_message') && data.nbPages > 0) {
            $potentiallyCreditedListPagination.removeClass('hidden');

            if (data.next) {
                $('.js-credit-potentially-credited-members-list-pagination-next').removeClass('disabled');
                $('.js-credit-potentially-credited-members-list-pagination-next').off('click').click(function () {
                    fetchPotentiallyCreditedMembers(data.next);
                });
            } else {
                $('.js-credit-potentially-credited-members-list-pagination-next').addClass('disabled');
            }

            if (data.prev) {
                $('.js-credit-potentially-credited-members-list-pagination-prev').removeClass('disabled');
                $('.js-credit-potentially-credited-members-list-pagination-prev').off('click').click(function () {
                    fetchPotentiallyCreditedMembers(data.prev);
                });
            } else {
                $('.js-credit-potentially-credited-members-list-pagination-prev').addClass('disabled');
            }
        } else {
            $potentiallyCreditedListPagination.addClass('hidden');
        }
    }

    function fetchPotentiallyCreditedMembers(page = 1) {
        $('.js-credit-potentially-credited-members-list').empty();
        creditTeamMembersCheckAllState = false;

        var $selectedTeamOption = $('#allsessions_credit_team_members_team').find(':selected');

        if (0 < $selectedTeamOption.length) {
            var teamId = $selectedTeamOption.val();

            $.ajax({
                type: 'GET',
                url: Routing.generate('team_members', {'team': teamId}),
                data: {page: page},
                success: function (data) {
                    fillPotentiallyCreditedMembers(data);
                    setupPagination(data);
                }
            });
        }
    }

    $('#allsessions_credit_team_members_team').change(function () {
        fetchPotentiallyCreditedMembers();
    });

    $('.js-credit-team-member-block-checkbox-input').click(function () {
        var checkedMemberId = $(this).attr('value');

        if ($(this).prop('checked')) {
            var newWidget = $("#allsessions_credit_team_members_memberIds").attr('data-prototype');
            newWidget = $(newWidget.replace(/__name__/g, checkedMemberId));
            newWidget.val(checkedMemberId);
            $creditMembersList.append(newWidget);
        } else {
            var $hiddenInput = $creditMembersList.find('input[value=' + checkedMemberId +'][type=\'hidden\']');
            $hiddenInput.remove();
        }

        updatePotentiallyCreditedMembersCount();
    });

    $('.js-credit-team-check-all').click(function (e) {
        e.preventDefault();
        creditTeamMembersCheckAllState = !creditTeamMembersCheckAllState;

        if (!creditTeamMembersCheckAllState) {
            var $hiddenInput = $creditMembersList.find('input[type=\'hidden\']');
            $hiddenInput.remove();
            $('.js-credit-team-member-block-checkbox-input').prop('checked', false);
        } else {
            $('.js-credit-team-member-block-checkbox-input', '.js-credit-potentially-credited-members-list').each(function () {
                if (!$(this).prop('checked')) {
                    var checkedMemberId = $(this).attr('value');
                    var newWidget = $("#allsessions_credit_team_members_memberIds").attr('data-prototype');

                    newWidget = $(newWidget.replace(/__name__/g, checkedMemberId));
                    newWidget.val(checkedMemberId);
                    $creditMembersList.append(newWidget);

                    $(this).prop('checked', true);
                }
            });
        }

        updatePotentiallyCreditedMembersCount();
    });

    $creditAdminTeamForm.submit(function(e) {
        var amountValue = parseFloat($creditAdminTeamFormAmount.val());

        if (amountValue > 100) {
            if(!confirm('Montant supérieur à 100 €. Continuer ?')) {
                e.preventDefault();
            }
        }
    });
});
