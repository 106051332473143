$(document).ready(function () {
    $companyChoice = $('.js-company-choice');

    if (0 === $companyChoice.length) {
        return;
    }

    $companyChoiceCompanies = $companyChoice.find('.js-company-choice-company');
    $companyChoiceList = $companyChoice.find('.js-company-choice-list');
    $companyChoiceSearch = $companyChoice.find('.js-company-choice-search');
    $companyChoiceEmptyListMessage = $companyChoice.find('.js-company-choice-empty-list-message');
    $companyChoiceRegisterNewCompany = $companyChoice.find('.js-company-choice-register-new-company');

    var slug = function(str) {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        var from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
        var to = 'aaaaaeeeeeiiiiooooouuuunc------';

        for (var i = 0, l = from.length ; i < l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');

        return str;
    };

    var toggleVisibility = function($element, isVisible) {
        if (isVisible) {
            $element.removeClass('hidden');

            return true;
        }

        $element.addClass('hidden');

        return false;
    };

    $companyChoiceSearch.on('keyup', function() {
        var companySlug = slug($(this).val());
        var countFilteredChoices = 0;

        $companyChoiceCompanies.filter(function() {
            if (toggleVisibility($(this), $(this).attr('data-company-slug').indexOf(companySlug) > -1)) {
                countFilteredChoices++;
            }
        });

        if (0 === countFilteredChoices) {
            $companyChoiceEmptyListMessage.removeClass('hidden');
            $companyChoiceList.addClass('hidden');
            $companyChoiceRegisterNewCompany.addClass('hidden');
        } else {
            $companyChoiceEmptyListMessage.addClass('hidden');
            $companyChoiceList.removeClass('hidden');
            $companyChoiceRegisterNewCompany.removeClass('hidden');
        }
    });

    $companyChoiceList.mCustomScrollbar({
        theme: 'dark-2'
    });
});
