$(document).ready(function() {
    var $sportSelectors = $('select[data-toggle="select2"].js-session-selector');

    if (0 === $sportSelectors.length) {
        return;
    }

    $.each($sportSelectors, function() {
        var $sportSelector = $(this);

        var selectTwoOptions = {
            dropdownAutoWidth: true,
            multiple: true,
            maximumSelectionLength: 99,
            allowClear: false,
            templateSelection: function setTag(data){
                if (!data.id) { return data.text; }
		        return data.text;
                // .slice(0,3) + '..';
            },
            tags: false,
            positionDropdown: true,
            width: '100%',
            language: {
                noResults: function() {
                    return 'session introuvable';
                }
            }
        };

        if ($sportSelector[0].hasAttribute('data-container-css-class')) {
            selectTwoOptions['containerCssClass'] = $sportSelector.attr('data-container-css-class');
        }

        if ($sportSelector[0].hasAttribute('data-dropdown-css-class')) {
            selectTwoOptions['dropdownCssClass'] = $sportSelector.attr('data-dropdown-css-class');
        }

        if ($sportSelector[0].hasAttribute('data-select2-placeholder')) {
            selectTwoOptions['placeholder'] = $sportSelector.attr('data-select2-placeholder');
        }

        var sportSelectorElement = $sportSelector.select2(selectTwoOptions);

        if ($sportSelector[0].hasAttribute('data-current-sport-id')) {
            var dataCurrentSportId = $sportSelector.attr('data-current-sport-id');
            sportSelectorElement.val(dataCurrentSportId).trigger('change');
        }

        if ($sportSelector[0].hasAttribute('data-dropdown-container-css-class')) {
            sportSelectorElement.data('select2').$dropdown.addClass($sportSelector.attr('data-dropdown-container-css-class'))
        }
    });
});
