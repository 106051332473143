$(document).ready(function() {
	////////////////////////////////////LISTE SPORTS POUR RECHERCHE/CREATION SESSION
	var $oneday = $('.oneday');

	$oneday.each(function(){
		disableEndDate($(this));

		$(this).on('click', function(){
			disableEndDate($(this));
		});
	});

	function disableEndDate(checkbox){
		if( checkbox.is(':checked') ) {
			checkbox.parent().parent().find('.disable-if-oneday').val('').prop( "disabled", true );
		} else {
			checkbox.parent().parent().find('.disable-if-oneday').prop( "disabled", false );
		}
	}

	// Place this in a separate component
	var $errorScrollableForm = $('.js-all-sessions-form');
	var $scrollableFormErrors = $errorScrollableForm.find('.js-all-sessions-form-error');

	if (0 < $scrollableFormErrors.length) {
		var $firstError = $scrollableFormErrors.first();

        var $previousFormSection = $firstError.closest('.js-all-sessions-form-section');

        if (0 < $previousFormSection.length) {
			$('html, body').animate({
				scrollTop: $previousFormSection.position().top
			}, 1000);
		}
    }

    $noPasteFields = $errorScrollableForm.find('.js-all-sessions-form-no-paste');

    if (0 < $noPasteFields.length) {
    	$noPasteFields.on('paste', function(e) {
    		return false;
		})
	}
});
