import './vendor_js';

import '../legacy/javascripts/autoload_popup';
import '../legacy/javascripts/collection';
import '../legacy/javascripts/company_choice';
import '../legacy/javascripts/credit_admin_team';
import '../legacy/javascripts/credit_purchase';
import '../legacy/javascripts/credit_usage';
import '../legacy/javascripts/date_picker';
import '../legacy/javascripts/default';
import '../legacy/javascripts/display_hidden';
import '../legacy/javascripts/distance_selector';
import '../legacy/javascripts/dropdown';
import '../legacy/javascripts/front_validation';
import '../legacy/javascripts/infinite_scroll';
import '../legacy/javascripts/inputs_masks';
import '../legacy/javascripts/interactive_form';
import '../legacy/javascripts/link_disabling';
import '../legacy/javascripts/member_choice';
import '../legacy/javascripts/member_credit_management';
import '../legacy/javascripts/membership_checkbox';

import '../legacy/javascripts/forms';

import '../legacy/javascripts/newsfeed_form';
import '../legacy/javascripts/notification_link';
import '../legacy/javascripts/popup';
import '../legacy/javascripts/profile_password';
import '../legacy/javascripts/session_invitation';
import '../legacy/javascripts/session_search';
import '../legacy/javascripts/search_alert';
import '../legacy/javascripts/simple_team_selection';
import '../legacy/javascripts/sport_selector';
import '../legacy/javascripts/session_selector';
import '../legacy/javascripts/recurrence_selector';
import '../legacy/javascripts/subscription';

//Used by the address_autocomplete in our .html.twig file
//TODO: Improve this
const $ = require('jquery');
global.$ = global.jQuery = $;
