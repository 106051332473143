$(document).ready(function() {
    var $creditPurchaseFormPopupContainer = $('.js-credit-purchase-popup-container');

    if (0 === $creditPurchaseFormPopupContainer.length) {
        return;
    }

    var $creditPurchaseFormPopupBackground = $creditPurchaseFormPopupContainer.find('.js-credit-purchase-form-popup-background');

    var openPagePopup = function ($pagePopup) {
        $pagePopup.removeClass('hidden');
        $creditPurchaseFormPopupBackground.removeClass('hidden');
    };

    var closePagePopup = function ($pagePopup) {
        $pagePopup.addClass('hidden');
        $creditPurchaseFormPopupBackground.addClass('hidden');
    };

    $(document).on('display_credit_purchase', function (e, missingCredits) {
        var $creditPurchaseFormPopup = $creditPurchaseFormPopupContainer.find('.js-credit-purchase-form-popup');

        if ('undefined' != typeof missingCredits) {
            $creditPurchaseFormPopup.find('.js-credit-purchase-missing-credits').html(missingCredits);
        }

        openPagePopup($creditPurchaseFormPopup);
    });

    //Closing when ESC is clicked
    $(document).keyup(function(event) {
        if(event.which=='27') {
            closePagePopup($creditPurchaseFormPopupContainer.find('.js-credit-purchase-form-popup'));
        }
    });

    $creditPurchaseFormPopupContainer.on('click', '.js-credit-purchase-popup-flash-close', function() {
        closePagePopup($creditPurchaseFormPopupContainer.find('.js-credit-purchase-form-popup'));
    });
});
