$(document).ready(function() {
    var $memberCreditManagement = $('.js-member-credit-management');

    if (0 === $memberCreditManagement.length) {
        return;
    }

    var $memberCreditManagementMemberInputs = $memberCreditManagement.find('.js-member-credit-management-member-input');

    var formatMember = function (member) {
        if (member.email != undefined) {
            var markup = "<div class='select2-result-member'>" +
                "<div class='select2-result-member__title'>" + member.firstname + ' ' + member.lastname + "</div>" +
                "<div style='font-size: 12px'>(" + member.email +")" +
                "</div>";

            return markup;
        }
    };

    $.each($memberCreditManagementMemberInputs, function () {
        var $selectedMemberIdInput = $(this);

        $(this).select2({
            dropdownAutoWidth: true,
            width: 'style',
            ajax: {
                url: Routing.generate('credit_search_members', {'is_transfer_source': true}),
                cache: true,
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        terms: params.term // search term
                    };
                },
                processResults: function (data) {
                    return {
                        results: data
                    };
                }
            },
            escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
            minimumInputLength: 2,
            templateResult: formatMember,
            templateSelection: function (memberData) {
                if ('undefined' !== typeof memberData.balance) {
                    var $memberBalance = $selectedMemberIdInput.closest('.js-member-credit-management-member-input-container').find('.js-member-credit-management-member-balance');
                    $memberBalance.text('Solde actuel : ' + parseFloat(memberData.balance) + ' crédits');
                    $memberBalance.removeClass('hidden');
                }

                if (memberData.firstname != undefined) {
                    return memberData.firstname + ' ' + memberData.lastname;
                }

                return memberData.text;
            }
        });
    });
});

$('#admin-credit-member-form').submit(function(event){
    if ($("#allsessions_credit_member_amount").val() > 100) {
        if(!confirm("Montant supérieur à 100 €. Continuer ?")){
            event.preventDefault();
        }
    }
});
