$(document).ready(function () {
    $searchAlertContainer = $('.js-search-alert');

    if (0 === $searchAlertContainer.length) {
        return;
    }

    $searchAlertDeleteForms = $searchAlertContainer.find('.js-search-alert-delete-form');
    $searchAlertDeletionPopup = $searchAlertContainer.find('.js-search-alert-deletion-popup');
    $searchAlertDeletionPopupClose = $searchAlertContainer.find('.js-search-alert-popup-flash-close');
    $searchAlertPopupBackground = $searchAlertContainer.find('.js-search-alert-popup-background');

    var openPagePopUp = function ($pagePopup) {
        $pagePopup.removeClass('hidden');
        $searchAlertPopupBackground.removeClass('hidden');
    };

    var closePagePopup = function ($pagePopup) {
        $pagePopup.addClass('hidden');
        $searchAlertPopupBackground.addClass('hidden');
    };

    $(document).keyup(function (event) {
        if (event.which == '27') {
            closePagePopup($searchAlertDeletionPopup);
        }
    });

    $searchAlertDeletionPopupClose.click(function () {
        closePagePopup($searchAlertDeletionPopup);
    });

    $searchAlertDeleteForms.submit(function(e) {
        e.preventDefault();

        var $form = $(this);

        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function () {
                $form.closest('.js-search-alert-container').remove();
                openPagePopUp($searchAlertDeletionPopup);
            }
        });
    });
});
