$(document).ready(function() {
    var $popupTriggers = $('.js-popup-trigger');

    if (0 === $popupTriggers.length) {
        return;
    }

    var $popupCloses = $('.js-popup-close');
    var $popupTargets = $('.js-popup-target');
    var $popupTargetBackgrounds = $('.js-popup-target-background');

    $popupTriggers.click(function(e) {
        e.preventDefault();

        var $popupContainer = $(this).closest('.js-popup-container');
        var $popupTarget = $popupContainer.find('.js-popup-target');
        var $popupTargetBackground = $popupContainer.find('.js-popup-target-background');

        $popupTarget.removeClass('hidden');
        $popupTargetBackground.removeClass('hidden');
    });

    $popupCloses.click(function(e) {
        e.preventDefault();

        var $popupContainer = $(this).closest('.js-popup-container');
        var $popupTarget = $popupContainer.find('.js-popup-target');
        var $popupTargetBackground = $popupContainer.find('.js-popup-target-background');

        $popupTarget.addClass('hidden');
        $popupTargetBackground.addClass('hidden');
    });

    $(document).keyup(function (event) {
        if (event.which == '27') {
            $popupTargets.addClass('hidden');
            $popupTargetBackgrounds.addClass('hidden');
        }
    });
});
