$(document).ready(function() {
    var $frontValidation = $('.js-front-validation');

    if (0 === $frontValidation.length) {
        return;
    }

    var $frontValidationFields = $frontValidation.find('.js-front-validation-input');
    var $frontValidationSubmit = $frontValidation.find('.js-front-validation-submit');

    $frontValidationFields.on('change input', function() {
        $(this).closest('.js-front-validation-container').find('.js-front-validation-input-error').addClass('hidden');
    });

    $frontValidationSubmit.click(function (e) {
        $.each($frontValidationFields, function() {
            var $field = $(this);
            var $value = $field.val();

            if (!$value || 0 === $value.length) {
                $field.closest('.js-front-validation-container').find('.js-front-validation-input-error').removeClass('hidden');
                e.preventDefault();
            }
        });
    });
});
