$(document).ready(function() {
    var $sessionInvitation = $('.js-session-invitation');

    if (0 === $sessionInvitation.length) {
        return;
    }

    var sessionInvitationFirstResult = 0;

    var sessionInvitationSessionId = $sessionInvitation.attr('data-session-id');

    var sessionInvitationValidEmails = [];
    var sessionInvitationInvalidEmails = [];

    // More Members Action
    var $sessionInvitationMoreMembersActionContainer = $sessionInvitation.find('.js-session-invitation-more-members-container');
    var $sessionInvitationMoreMembersAction = $sessionInvitationMoreMembersActionContainer.find('.js-session-invitation-more-members');

    // Potentially Invited Member Elements
    var $sessionPotentiallyInvitedList = $sessionInvitation.find('.js-session-invitation-potentially-invited-list');

    // Distance Selector
    var $sessionInvitationDistanceContainer = $sessionInvitation.find('.js-session-invitation-distance-container');
    var $sessionInvitationDistanceChoices = $sessionInvitation.find('.js-session-invitation-distance-selector-choice');

    var $sessionInvitationMembersList = $sessionInvitation.find('.js-session-invitation-members-list');
    var $sessionInvitationEmailsList = $sessionInvitation.find('.js-session-invitation-emails-list');
    var sessionInvitationEmailsListId = $sessionInvitationEmailsList.attr('id');
    var $sessionInvitationEmails = $sessionInvitation.find('.js-session-invitation-emails');

    //category contents
    var $sessionInvitationCategories = $sessionInvitation.find('.js-session-invitation-category');
    var $sessionInvitationCategoryContents = $sessionInvitation.find('.js-session-invitation-category-content');

    var $sessionInvitationCategoryContentToInvite = $sessionInvitation.find('.js-session-invitation-category-content[data-invitation-category="to-invite"]');
    var $sessionInvitationSelectAllContainer = $sessionInvitationCategoryContentToInvite.find('.js-session-invitation-select-all-container');

    //Select All
    var $sessionInvitationSelectAll = $sessionInvitationSelectAllContainer.find('.js-session-invitation-select-all');
    var $sessionInvitationSelectAllDisplayCheckbox = $sessionInvitationSelectAllContainer.find('.js-session-invitation-select-all-display-checkbox');
    //Unselect All
    var $sessionInvitationUnSelectAll = $sessionInvitationSelectAllContainer.find('.js-session-invitation-unselect-all');
    var $sessionInvitationUnSelectAllDisplayCheckbox = $sessionInvitationSelectAllContainer.find('.js-session-invitation-unselect-all-display-checkbox');

    // Filter Checkboxes (Sport, Team, Address)
    var $sessionInvitationFilterCheckBoxes = $sessionInvitation.find('.js-session-invitation-filter-checkbox');
    var $sessionInvitationFilterDisplayCheckBoxes = $sessionInvitation.find('.js-session-invitation-filter-display-checkbox');
    var $sessionInvitationFilterSport = $sessionInvitation.find('.js-session-invitation-filter-sport');
    var $sessionInvitationFilterTeam = $sessionInvitation.find('.js-session-invitation-filter-team');
    var $sessionInvitationFilterAddress = $sessionInvitation.find('.js-session-invitation-filter-address');

    // Submit button
    var $sessionInvitationSendButton = $sessionInvitationCategoryContentToInvite.find('.js-session-invitation-send-invitations');

    var $sessionInvitationCategoryContentToInviteByEmail = $sessionInvitation.find('.js-session-invitation-category-content[data-invitation-category="to-invite-by-email"]');
    var $sessionInvitationEmailForm = $sessionInvitation.find('.js-session-invitation-send-invitations-by-email');

    var $sessionInvitationEmailErrors  = $sessionInvitationEmailForm.find('.js-session-invitation-emails-error');

    var $sessionInvitationLoader  = $sessionInvitation.find('.js-session-invitation-loader');

    //SubmitButtonForEmails
    var $sessionInvitationForEmailsSendButton = $sessionInvitationCategoryContentToInviteByEmail.find('.js-session-email-invitation-send-invitations');

    $sessionInvitationCategories.click(function (e) {
        var $selectedCategory = $(e.target);
        var selectedCategoryTargetSelector = $selectedCategory.attr('data-invitation-category-target');

        $sessionInvitationCategories.removeClass('active');
        $sessionInvitationCategoryContents.addClass('hidden');

        $selectedCategory.addClass('active');
        $(selectedCategoryTargetSelector).removeClass('hidden');

        $sessionInvitationInfiniteScroll = $(selectedCategoryTargetSelector).find('.js-session-invitation-infinite-scroll');

        if (0 < $sessionInvitationInfiniteScroll.length) {
            $sessionInvitationInfiniteScroll.trigger('infinite_scroll_enable_for_tab');
        } else {
            $selectedCategory.trigger('infinite_scroll_enable_for_tab');
        }
    });

    var loadRequestedMembers = function (filters, isMoreMembers) {
        var urlData = {'first_result': sessionInvitationFirstResult};

        if (!filters['likes_sport'] && !filters['in_same_team'] && !filters['has_close_address']) {
            if (0 === $sessionPotentiallyInvitedList.length || !$sessionPotentiallyInvitedList[0].hasAttribute('data-team-dedicated')) {
                $sessionInvitationMoreMembersActionContainer.addClass('hidden');

                return;
            }
        }

        for (var filter in filters) {
            if (filters.hasOwnProperty(filter)) {
                urlData[filter] = filters[filter];
            }
        }

        urlData['distance'] = $sessionInvitationDistanceContainer.attr('data-current-distance');

        var route = Routing.generate('invitation_get_potentially_invited_members', {'session': sessionInvitationSessionId});

        $sessionInvitationLoader.removeClass('hidden');
        $sessionInvitationMoreMembersActionContainer.addClass('hidden');
        $sessionInvitationSendButton.addClass('hidden');

        $.ajax({
            type: 'GET',
            url: route,
            data: urlData,
            success: function (data, statusText, xhr) {
                if (xhr.status === 200) {
                    $sessionInvitationLoader.addClass('hidden');

                    if (!data.hasOwnProperty('no_members')) {
                        $sessionPotentiallyInvitedList.append(data['members_view']);

                        sessionInvitationFirstResult += data.members_data_length;

                        if (data.has_more) {
                            $sessionInvitationMoreMembersActionContainer.removeClass('hidden');
                        }

                        $sessionInvitationSendButton.removeClass('hidden');

                        $sessionInvitationSelectAllContainer.removeClass('hidden');

                        if ($sessionInvitationSelectAll.prop('checked')) {
                            var $uncheckedDisplayCheckboxes = $sessionPotentiallyInvitedList.find('.js-session-invitation-member-checkbox:not(:checked)').closest('.js-session-invitation-checkbox-container').find('.js-session-invitation-display-checkbox');
                            $uncheckedDisplayCheckboxes.trigger('click');
                        }

                        if ($sessionInvitationUnSelectAll.prop('checked')) {
                            $sessionInvitationUnSelectAll.prop('checked', false);
                            $sessionInvitationUnSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked').removeClass('js-session-invitation-display-checkbox-checked');
                        }
                    } else {
                        if (!isMoreMembers) {
                            $sessionPotentiallyInvitedList.html(data['no_members_view']);
                        } else {
                            $sessionPotentiallyInvitedList.append(data['no_members_view']);
                            $sessionInvitationSendButton.removeClass('hidden');
                        }

                        $sessionInvitationMoreMembersActionContainer.addClass('hidden');
                    }

                    $sessionInvitationFilterDisplayCheckBoxes.removeAttr('data-freeze-listener');
                }
            }
        });
    };

    // Updating List of members according to selected filter //
    var resetLoadMembersData = function() {
        $sessionPotentiallyInvitedList.empty();

        sessionInvitationFirstResult = 0;

        $sessionInvitationSelectAllContainer.addClass('hidden');

        $sessionInvitationSelectAll.prop('checked', false);
        $sessionInvitationSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked').removeClass('js-session-invitation-display-checkbox-checked');

        $sessionInvitationUnSelectAll.prop('checked', false);
        $sessionInvitationUnSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked').removeClass('js-session-invitation-display-checkbox-checked');

        $sessionInvitationMoreMembersActionContainer.addClass('hidden');

        $sessionInvitationSendButton.addClass('hidden');
    };

    if (0 < $sessionPotentiallyInvitedList.length && $sessionPotentiallyInvitedList[0].hasAttribute('data-team-dedicated')) {
        var filters = {
            'likes_sport': false,
            'in_same_team': true,
            'has_close_address': false
        };

        loadRequestedMembers(filters, false);
    }

    $sessionInvitationFilterCheckBoxes.change(function() {
        var filters = {
            'likes_sport': $sessionInvitationFilterSport.prop('checked'),
            'in_same_team': $sessionInvitationFilterTeam.prop('checked'),
            'has_close_address': $sessionInvitationFilterAddress.prop('checked')
        };

        resetLoadMembersData();
        loadRequestedMembers(filters, false);

        return true;
    });

    $sessionInvitationDistanceContainer.on('data_current_distance_change', $sessionInvitationDistanceChoices, function() {
        var countCheckedFilters = 0;

        $.each($sessionInvitationFilterCheckBoxes, function() {
           if ($(this).prop('checked')) {
               countCheckedFilters++;
           }
        });

        if (0 === countCheckedFilters) {
            return;
        }

        var filters = {
            'likes_sport': $sessionInvitationFilterSport.prop('checked'),
            'in_same_team': $sessionInvitationFilterTeam.prop('checked'),
            'has_close_address': $sessionInvitationFilterAddress.prop('checked')
        };

        resetLoadMembersData();

        loadRequestedMembers(filters, false);
    });

    $sessionInvitationCategoryContentToInvite.on('click', '.js-session-invitation-display-checkbox', function () {
        if ($(this)[0].hasAttribute('data-freeze-listener')) {
            return;
        }

        var $relatedCheckbox;

        if (!$(this).hasClass('js-session-invitation-display-checkbox-checked')) {
            if ($(this).hasClass('js-session-invitation-filter-display-checkbox')) {
                $(this).attr('data-freeze-listener', 'true');
            }

            $relatedCheckbox = $(this).closest('.js-session-invitation-checkbox-container').find('.js-session-invitation-checkbox');

            $relatedCheckbox.trigger('click');
            $relatedCheckbox.prop('checked', true);

            $(this).addClass('session-invitation-display-checkbox-checked');
            $(this).addClass('js-session-invitation-display-checkbox-checked');
        } else {
            $relatedCheckbox = $(this).closest('.js-session-invitation-checkbox-container').find('.js-session-invitation-checkbox');

            $relatedCheckbox.trigger('click');
            $relatedCheckbox.prop('checked', false);

            $(this).removeClass('session-invitation-display-checkbox-checked');
            $(this).removeClass('js-session-invitation-display-checkbox-checked');
        }
    });

    $sessionInvitationSelectAll.change(function () {
        if ($(this).prop('checked')) {
            var $uncheckedDisplayCheckboxes = $sessionPotentiallyInvitedList.find('.js-session-invitation-member-checkbox:not(:checked)').closest('.js-session-invitation-checkbox-container').find('.js-session-invitation-display-checkbox');
            $uncheckedDisplayCheckboxes.trigger('click');
        }

        if ($sessionInvitationUnSelectAll.prop('checked')) {
            $sessionInvitationUnSelectAll.prop('checked', false);
            $sessionInvitationUnSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked').removeClass('js-session-invitation-display-checkbox-checked');
        }
    });

    $sessionInvitationUnSelectAll.change(function () {
        if ($(this).prop('checked')) {
            var $uncheckedDisplayCheckboxes = $sessionPotentiallyInvitedList.find('.js-session-invitation-member-checkbox:checked').closest('.js-session-invitation-checkbox-container').find('.js-session-invitation-display-checkbox');
            $uncheckedDisplayCheckboxes.trigger('click');
        }

        if ($sessionInvitationSelectAll.prop('checked')) {
            $sessionInvitationSelectAll.prop('checked', false);
            $sessionInvitationSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked').removeClass('js-session-invitation-display-checkbox-checked');
        }
    });

    //Getting more members to invite
    $sessionInvitationMoreMembersAction.click(function () {
        var filters = {
            'likes_sport': $sessionInvitationFilterSport.prop('checked'),
            'in_same_team': $sessionInvitationFilterTeam.prop('checked'),
            'has_close_address': $sessionInvitationFilterAddress.prop('checked')
        };

        loadRequestedMembers(filters, true);
    });

    $sessionPotentiallyInvitedList.on('change', '.js-session-invitation-member-checkbox', function(e) {
        var $checkedInput = $(e.target);

        var checkedMemberId = $checkedInput.attr('value');

        if ($checkedInput.prop('checked')) {
            var $hiddenField = $('<input type="hidden"></input>');
            var formInputId = $sessionInvitationMembersList.attr('id');

            $hiddenField.attr('id', formInputId + '_' + checkedMemberId);
            $hiddenField.attr('name', 'allsessions_members_invitation[members_list]' + '[' + checkedMemberId + ']');
            $hiddenField.attr('value', checkedMemberId);

            $sessionInvitationMembersList.append($hiddenField);

            if ($sessionInvitationUnSelectAll.prop('checked')) {
                $sessionInvitationUnSelectAll.prop('checked', false);

                $sessionInvitationUnSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked');
                $sessionInvitationUnSelectAllDisplayCheckbox.removeClass('js-session-invitation-display-checkbox-checked');
            }
        } else {
            var $hiddenInput = $sessionInvitationMembersList.find('input[value=\'' + checkedMemberId + '\']');

            $hiddenInput.remove();

            if ($sessionInvitationSelectAll.prop('checked')) {
                $sessionInvitationSelectAll.prop('checked', false);

                $sessionInvitationSelectAllDisplayCheckbox.removeClass('session-invitation-display-checkbox-checked');
                $sessionInvitationSelectAllDisplayCheckbox.removeClass('js-session-invitation-display-checkbox-checked');
            }
        }
    });

    $sessionInvitationEmails.focus(function() {
        sessionInvitationInvalidEmails = [];
        sessionInvitationValidEmails = [];
        $sessionInvitationEmailsList.empty();
    });

    var hasValidEmailRegexp = function(email) {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return regex.test(email);
    };

    var displayErrorMessage = function(errorMessage) {
        $sessionInvitationEmailErrors.removeClass('hidden');
        $sessionInvitationEmailErrors.text(errorMessage);
    };

    $sessionInvitationForEmailsSendButton.click(function(e) {
        e.preventDefault();

        $sessionInvitationEmailErrors.empty();

        var emails = $sessionInvitationEmails.val().split(/[ ,;\n]+/);
        var hasEmailError = false;

        emails.forEach(function (email) {
            var newEmail = email.replace("<", "").replace(">", "");

            if (!hasValidEmailRegexp(newEmail)) {
                if (newEmail !== '') {
                    sessionInvitationInvalidEmails.push(newEmail);

                    hasEmailError = true
                }
            } else {
                sessionInvitationValidEmails.push(newEmail);
            }
        });

        sessionInvitationValidEmails.forEach(function (validEmail, index) {
            var $hiddenField = $('<input type="hidden"></input>');

            $hiddenField.attr('value', validEmail);
            $hiddenField.attr('id', sessionInvitationEmailsListId + '_' + (index + 1));
            $hiddenField.attr('name', 'allsessions_emails_invitation[emails_list]' + '[' + (index + 1) + ']');

            $sessionInvitationEmailsList.append($hiddenField);
        });

        if (0 === sessionInvitationInvalidEmails.length) {
            $sessionInvitationEmailForm.submit();
        } else {
            var errorMessage = 'Les emails suivants sont invalides : '+sessionInvitationInvalidEmails.join(', ');

            displayErrorMessage(errorMessage);
        }
    });
});
