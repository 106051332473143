$(document).ready(function() {
    var $dropdownContainers = $('.js-dropdown-container');

    if (0 === $dropdownContainers.length) {
        return;
    }

    $.each($dropdownContainers, function() {
        var $dropdownContainer = $(this);

        var $dropdownTrigger = $dropdownContainer.find('.js-dropdown-trigger');
        var $dropdownTriggerIcon = $dropdownTrigger.find('.js-dropdown-trigger-icon');
        var $dropdownTarget = $dropdownContainer.find('.js-dropdown-target');

        $dropdownTrigger.click(function() {
            $dropdownTarget.toggleClass('hidden');
            $dropdownTriggerIcon.toggleClass('fa-caret-down').toggleClass('fa-caret-up');
        })
    });
});
