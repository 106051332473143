$(document).ready(function () {
    var $infiniteScrolls = $('.js-infinite-scroll');

    if (0 === $infiniteScrolls.length) {
        return;
    }

    var infiniteScrollOffsets = {};

    $.each ($infiniteScrolls, function(index) {
        var $infiniteScroll = $(this);
        var infiniteScrollExtraResultsBasicUrl = $infiniteScroll.attr('data-extra-results-url');
        var infiniteScrollExtraResultsUrlType = $infiniteScroll.attr('data-extra-results-url-type');
        var $infiniteScrollLoaderContainer = $infiniteScroll.find('.js-infinite-scroll-loader-container');
        var $infiniteScrollResultsContainer = $infiniteScroll.find('.js-infinite-scroll-results-container');
        var infiniteScrollExtraResultsData = {};
        infiniteScrollOffsets[index] = 21;

        if ($infiniteScroll[0].hasAttribute('data-initially-disabled')) {
            $(window).data('ajaxready', false);
        } else {
            $(window).data('ajaxready', true);
        }

        $(document).on('infinite_scroll_url_change', function(e, newExtraResultsData) {
            $infiniteScrollLoaderContainer.addClass('hidden');

            infiniteScrollExtraResultsBasicUrl = newExtraResultsData['url'];
            infiniteScrollExtraResultsUrlType = newExtraResultsData['url_type'];
            infiniteScrollExtraResultsData = newExtraResultsData['data'];
            infiniteScrollOffsets[index] = 21;
        });

        $(document).on('infinite_scroll_enable', function() {
            $(window).data('ajaxready', true);
        });

        $(document).on('infinite_scroll_disable', function() {
            $(window).data('ajaxready', false);
            $infiniteScrollLoaderContainer.addClass('hidden');
        });

        $(document).on('infinite_scroll_enable_for_tab', function(e) {
            $infiniteScrolls.attr('data-disabled-scroll-for-tab', true);
            var $enabledInfiniteScroll = $(e.target);

            if ($enabledInfiniteScroll.hasClass('js-infinite-scroll')) {
                $enabledInfiniteScroll.removeAttr('data-disabled-scroll-for-tab');
            }
        });

        var deviceAgent = navigator.userAgent.toLowerCase();
        var agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
        var scrollStartPercentageThreshold = 0.9;

        $(window).scroll(function() {
            if (false == $(window).data('ajaxready')) {
                return;
            }

            if ($infiniteScroll[0].hasAttribute('data-disabled-scroll-for-tab')) {
                return;
            }

            if ('end' === infiniteScrollOffsets[index]) {
                return;
            }

            if(($(window).scrollTop() + $(window).height() + 150) > scrollStartPercentageThreshold * $(document).height()
                || agentID && ($(window).scrollTop() + $(window).height()) + 150 > $(document).height()) {
                $(window).data('ajaxready', false);

                $infiniteScrollLoaderContainer.removeClass('hidden');

                var url;

                if (infiniteScrollExtraResultsBasicUrl.indexOf('?') > -1) {
                    url = infiniteScrollExtraResultsBasicUrl + '&offset=' + infiniteScrollOffsets[index];
                } else {
                    url = infiniteScrollExtraResultsBasicUrl + '?offset=' + infiniteScrollOffsets[index];
                }

                $.ajax({
                    type: infiniteScrollExtraResultsUrlType,
                    url: url,
                    data: infiniteScrollExtraResultsData,
                    dataType: 'json',
                    success: function(data) {
                        if (0 === data['extra_results_count']) {
                            $infiniteScrollLoaderContainer.addClass('hidden');
                            infiniteScrollOffsets[index] = 'end';

                            if (1 < $infiniteScrolls.length) {
                                $(window).data('ajaxready', true);
                            }

                            return;
                        }

                        $infiniteScrollResultsContainer.append($.parseHTML(data['extra_results_view']));
                        infiniteScrollOffsets[index] += data['extra_results_count'];
                        $infiniteScrollLoaderContainer.addClass('hidden');

                        $(window).data('ajaxready', true);
                    }
                });
            }
        });
    });
});
