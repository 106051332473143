$(document).ready(function() {
    var $creditUsageFormPopupContainer = $('.js-credit-usage-popup-container');

    if (0 === $creditUsageFormPopupContainer.length) {
        return;
    }

    var $creditUsageFormPopupBackground = $creditUsageFormPopupContainer.find('.js-credit-usage-form-popup-background');

    var openPagePopup = function ($pagePopup) {
        $pagePopup.removeClass('hidden');
        $creditUsageFormPopupBackground.removeClass('hidden');
    };

    var closePagePopup = function ($pagePopup) {
        $pagePopup.addClass('hidden');
        $creditUsageFormPopupBackground.addClass('hidden');
    };

    $(document).on('display_credit_usage', function () {
        var $creditUsageFormPopup = $creditUsageFormPopupContainer.find('.js-credit-usage-form-popup');
        openPagePopup($creditUsageFormPopup);
    });

    //Closing when ESC is clicked
    $(document).keyup(function(event) {
        if(event.which=='27') {
            closePagePopup($creditUsageFormPopupContainer.find('.js-credit-usage-form-popup'));
        }
    });

    $creditUsageFormPopupContainer.on('click', '.js-credit-usage-popup-flash-close', function() {
        closePagePopup($creditUsageFormPopupContainer.find('.js-credit-usage-form-popup'));
    });
});
