$(document).ready(function() {
   $interactiveForm = $('.js-interactive-form');

   if (0 === $interactiveForm.length) {
       return;
   }

   $interactiveFormSubmit = $interactiveForm.find('.js-interactive-form-submit');

    $interactiveForm.submit(function() {
        $interactiveFormSubmit.text('Chargement ...');
        $interactiveFormSubmit.addClass('button-submit-disabled');
        $interactiveFormSubmit.attr('disabled', 'disabled');
    })
});
