$(document).ready(function () {
    var $autoloadPopup = $('.js-autoload-popup');

    if (0 === $autoloadPopup.length) {
        return;
    }

    var $autoloadPopupBackground = $autoloadPopup.find('.js-autoload-popup-background');
    var $autoloadPopupServerElements = $autoloadPopup.find('.js-autoload-popup-server-element');

    var openPagePopUp = function ($pagePopupServerFlash) {
        $pagePopupServerFlash.removeClass('hidden');
        $autoloadPopupBackground.removeClass('hidden');
    };

    if (0 < $autoloadPopupServerElements.length) {
        openPagePopUp($autoloadPopupServerElements.first());
    }

    var closeCurrentPagePopUp = function ($pagePopupServerFlash) {
        $pagePopupServerFlash.addClass('hidden');
        $autoloadPopupBackground.addClass('hidden');
    };

    $autoloadPopupServerElements.on('click', '.js-autoload-popup-remove', function () {
        closeCurrentPagePopUp($(this).closest('.js-autoload-popup-server-element'));
    });

    $(document).keyup(function (event) {
        // If ESCP
        if (event.which == '27') {
            $autoloadPopupServerElements.each(function() {
                if (!$(this).hasClass('hidden')) {
                    closeCurrentPagePopUp($(this));
                }
            });
        }
    });
});

