$(document).ready(function() {
    $membershipCheckBox = $('.js-membership-checkbox');

    if (0 === $membershipCheckBox.length) {
        return;
    }

    $membershipCheckBoxForm = $membershipCheckBox.find('.js-membership-checkbox-form');
    $membershipCheckBoxFormSubmit = $membershipCheckBox.find('.js-membership-checkbox-form-submit');
    $membershipCheckBoxCollectionInput = $membershipCheckBox.find('.js-membership-checkbox-collection-input');
    $membershipCheckBoxDateInputs = $membershipCheckBox.find('.js-membership-checkbox-date-input');
    $membershipCheckBoxDisplayCheckboxes = $membershipCheckBox.find('.js-membership-checkbox-display-checkbox');
    $membershipCheckBoxTotalPrice = $membershipCheckBox.find('.js-membership-checkbox-total-price');
    $membershipCheckBoxTotalPriceValue = $membershipCheckBox.find('.js-membership-checkbox-total-price-value');
    $membershipCheckBoxAccessibleSessions = $membershipCheckBox.find('.js-membership-checkbox-accessible-sessions');
    $membershipCheckBoxLoader = $membershipCheckBox.find('.js-membership-checkbox-loader-container');
    $membershipCheckBoxLoaderCreditPurchaseFormContainer = $membershipCheckBox.find('.js-membership-checkbox-credit-purchase-form-container');
    $membershipCheckBoxLoaderCreditUsageFormContainer = $membershipCheckBox.find('.js-membership-checkbox-credit-usage-form-container');

    membershipCheckBoxPrice = parseInt($membershipCheckBox.attr('data-membership-price'));
    membershipCheckBoxTotal = 0;

    $membershipCheckBoxDateInputs.prop('checked', false);

    var membershipCheckBoxSessionsUrl = Routing.generate('subscription_get_extra_sessions_within_dates', {'subscription': parseInt($membershipCheckBox.attr('data-subscription-id'))});
    var membershipCheckBoxEndDates = {};

    var displayAccessibleSessions = function ($checkedInput) {
        var urlData = {'end_dates': JSON.stringify(membershipCheckBoxEndDates)};

        $.ajax({
            type: 'GET',
            url: membershipCheckBoxSessionsUrl + '?offset=0',
            data: urlData,
            dataType: 'json',
            success: function (data) {
                $membershipCheckBoxLoader.addClass('hidden');
                $membershipCheckBoxAccessibleSessions.html($.parseHTML(data['extra_results_view']));

                if (0 < data['extra_results_count']) {
                    $membershipCheckBoxAccessibleSessions.trigger('infinite_scroll_enable');
                } else {
                    $membershipCheckBoxAccessibleSessions.trigger('infinite_scroll_disable');
                }

                var infiniteSrcollData = {'url': membershipCheckBoxSessionsUrl, 'url_type': 'GET', 'data': urlData};
                $membershipCheckBox.trigger('infinite_scroll_url_change', infiniteSrcollData);

                if (null !== $checkedInput) {
                    $checkedInput.closest('.js-membership-checkbox-checkbox-container').find('.js-membership-checkbox-display-checkbox').removeAttr('disabled');
                }
            }
        });
    };

    if ($membershipCheckBox[0].hasAttribute('data-has-stripe-price')) {
        $.each($membershipCheckBox.find('.js-membership-checkbox-stripe-period'), function () {
            var subscribedEndDate = $(this).attr('data-end-date');
            membershipCheckBoxEndDates[subscribedEndDate] = subscribedEndDate;
            displayAccessibleSessions(null);
        });

        if ($membershipCheckBox[0].hasAttribute('data-stripe-session-id')) {
            $membershipCheckBoxFormSubmit.click(function() {
                var stripe = Stripe($membershipCheckBox.attr('data-stripe-publishable-key'));

                stripe
                    .redirectToCheckout({
                        sessionId: $membershipCheckBox.attr('data-stripe-session-id')
                    })
                    .then(() => console.log('Handled result'))
                ;
            });
        }
    } else {
        $.each($membershipCheckBox.find('.js-membership-checkbox-display-checkbox[data-status="subscribed"]'), function () {
            var subscribedEndDate = $(this).attr('data-subscribed-end-date');

            membershipCheckBoxEndDates[subscribedEndDate] = subscribedEndDate;
        });

        if (!$.isEmptyObject(membershipCheckBoxEndDates)) {
            $membershipCheckBoxLoader.removeClass('hidden');
            displayAccessibleSessions(null);
        }

        $membershipCheckBoxDisplayCheckboxes.click(function () {
            var $displayCheckbox = $(this);

            if ('subscribed' === $displayCheckbox.attr('data-status')) {
                return;
            }

            if ($displayCheckbox[0].hasAttribute('disabled')) {
                return;
            }

            $displayCheckbox.attr('disabled', 'disabled');

            var $dateInput;

            $dateInput = $displayCheckbox.closest('.js-membership-checkbox-checkbox-container').find('.js-membership-checkbox-date-input');
            $dateInput.trigger('click');

            if ($displayCheckbox.hasClass('js-membership-checkbox-display-checkbox-checked')) {
                $dateInput.prop('checked', false);
                $displayCheckbox.removeClass('custom-display-checkbox-checked');
                $displayCheckbox.removeClass('js-membership-checkbox-display-checkbox-checked');
            } else {
                $dateInput.prop('checked', true);
                $displayCheckbox.addClass('custom-display-checkbox-checked');
                $displayCheckbox.addClass('js-membership-checkbox-display-checkbox-checked');
            }
        });

        $membershipCheckBoxDateInputs.change(function(e) {
            $membershipCheckBoxAccessibleSessions.empty();
            $membershipCheckBoxLoader.removeClass('hidden');
            $membershipCheckBoxLoaderCreditPurchaseFormContainer.empty();
            $membershipCheckBoxLoaderCreditUsageFormContainer.empty();

            var $checkedInput = $(e.target);
            var membershipEndDate = $checkedInput.attr('value');

            if ($checkedInput.prop('checked')) {
                var $hiddenField = $('<input>');
                var formInputId = $membershipCheckBoxCollectionInput.attr('id');
                var hiddenFieldIdRoot = membershipEndDate.substring(0, 10);

                $hiddenField.attr('type', 'hidden');
                $hiddenField.attr('id', formInputId + '_' + hiddenFieldIdRoot);
                $hiddenField.attr('name', 'allsessions_membership_end_dates[end_dates]' + '[' + hiddenFieldIdRoot + ']');
                $hiddenField.attr('value', membershipEndDate);

                $membershipCheckBoxCollectionInput.append($hiddenField);

                membershipCheckBoxTotal += membershipCheckBoxPrice;

                membershipCheckBoxEndDates[membershipEndDate] = membershipEndDate;
            } else {
                var $hiddenInput = $membershipCheckBoxCollectionInput.find('input[value=\'' + membershipEndDate + '\']');

                $hiddenInput.remove();

                membershipCheckBoxTotal -= membershipCheckBoxPrice;

                delete membershipCheckBoxEndDates[membershipEndDate];
            }

            if (0 === membershipCheckBoxTotal) {
                $membershipCheckBoxTotalPrice.addClass('text-opacity-0');
                $membershipCheckBoxTotalPriceValue.text(membershipCheckBoxTotal);
                $membershipCheckBoxFormSubmit.addClass('button-submit-disabled');
                $membershipCheckBoxFormSubmit.attr('disabled', 'disabled');
                $membershipCheckBoxFormSubmit.html('Coche les périodes souhaitées');

                if ($.isEmptyObject(membershipCheckBoxEndDates)) {
                    $membershipCheckBoxLoader.addClass('hidden');
                    $membershipCheckBoxAccessibleSessions.trigger('infinite_scroll_disable');
                    $membershipCheckBoxAccessibleSessions.empty();
                } else {
                    displayAccessibleSessions(null);
                }

                $checkedInput.closest('.js-membership-checkbox-checkbox-container').find('.js-membership-checkbox-display-checkbox').removeAttr('disabled');
            } else {
                $membershipCheckBoxTotalPriceValue.text(membershipCheckBoxTotal);
                $membershipCheckBoxTotalPrice.removeClass('text-opacity-0');
                $membershipCheckBoxFormSubmit.removeClass('button-submit-disabled');
                $membershipCheckBoxFormSubmit.removeAttr('disabled');
                $membershipCheckBoxFormSubmit.html('Prendre mon abonnement');
                displayAccessibleSessions($checkedInput);
            }
        });

        $membershipCheckBoxFormSubmit.click(function() {
            if ($membershipCheckBoxFormSubmit[0].hasAttribute('disabled')) {
                return;
            }

            $membershipCheckBoxFormSubmit.attr('disabled', 'disabled');
            $membershipCheckBoxFormSubmit.addClass('button-submit-disabled');
            $membershipCheckBoxFormSubmit.html('Chargement ...');
            $membershipCheckBoxForm.submit();
        });

        $membershipCheckBoxForm.submit(function(e) {
            e.preventDefault();

            if (0 < $membershipCheckBoxLoaderCreditPurchaseFormContainer.find('.js-membership-checkbox-credit-purchase-form').length) {
                $membershipCheckBoxLoaderCreditPurchaseFormContainer.trigger('display_credit_purchase');

                return;
            }

            if (0 < $membershipCheckBoxLoaderCreditUsageFormContainer.find('.js-membership-checkbox-credit-usage-form').length) {
                $membershipCheckBoxLoaderCreditUsageFormContainer.trigger('display_credit_usage');

                return;
            }

            var $form = $(this);

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: $form.serialize(),
                success: function (data) {
                    if ('undefined' !== typeof data['credit_purchase_popup']) {
                        $membershipCheckBoxLoaderCreditPurchaseFormContainer.html($.parseHTML(data['credit_purchase_popup']));
                        $membershipCheckBoxLoaderCreditPurchaseFormContainer.trigger('display_credit_purchase', data['missing_credits']);
                    } else {
                        $membershipCheckBoxLoaderCreditUsageFormContainer.html($.parseHTML(data['credit_usage_popup']));
                        $membershipCheckBoxLoaderCreditUsageFormContainer.trigger('display_credit_usage', data['missing_credits']);
                    }

                    $membershipCheckBoxFormSubmit.removeAttr('disabled');
                    $membershipCheckBoxFormSubmit.removeClass('button-submit-disabled');
                    $membershipCheckBoxFormSubmit.html('Prendre mon abonnement');
                }
            });
        });
    }
});
