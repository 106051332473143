$(document).ready(function() {
    var $subscription = $('.js-subscription');

    if (0 === $subscription.length) {
        return;
    }

    var $subscriptionPopUpBackground = $subscription.find('.js-subscription-popup-background');
    var $subscriptionPublishedPopup = $subscription.find('.js-subscription-published-popup');
    var $subscriptionUnPublishedPopup = $subscription.find('.js-subscription-unpublished-popup');
    var $subscriptionPopupFlashClose = $subscription.find('.js-subscription-popup-flash-close');
    var $subscriptionPublishers = $subscription.find('.js-subscription-publisher');
    var $subscriptionUnPublishers = $subscription.find('.js-subscription-unpublisher');
    var $subscriptionSessionSubscriptionExcluders = $subscription.find('.js-subscription-session-subscription-excluder');
    var $subscriptionSessionSubscriptionIncluders = $subscription.find('.js-subscription-session-subscription-includer');

    var openPagePopUp = function ($pagePopup) {
        $pagePopup.removeClass('hidden');
        $subscriptionPopUpBackground.removeClass('hidden');
    };

    var closePagePopup = function ($pagePopup) {
        $pagePopup.addClass('hidden');
        $subscriptionPopUpBackground.addClass('hidden');
    };

    $(document).keyup(function (event) {
        if (event.which == '27') {
            closePagePopup($subscriptionPublishedPopup);
            closePagePopup($subscriptionUnPublishedPopup);
        }
    });

    $.each($subscriptionPublishers, function() {
        var $subscriptionPublisher = $(this);

        var subscriptionId = $subscriptionPublisher.attr('data-subscription-id');

        $subscriptionPublisher.click(function(e) {
            e.preventDefault();

            var route = Routing.generate('subscription_publish', {'subscription': subscriptionId});
            $.ajax({
                url: route,
                success: function(data) {
                    if (data.published) {
                        var $subscriptionSingle = $subscriptionPublisher.closest('.js-subscription-single');

                        $subscriptionSingle.removeClass('admin-subscription-single-unpublished');
                        $subscriptionSingle.find('.js-subscription-single-status').remove();
                        $subscriptionPublisher.remove();

                        openPagePopUp($subscriptionPublishedPopup);
                    }
                }
            });
        })
    });

    $.each($subscriptionUnPublishers, function() {
        var $subscriptionUnPublisher = $(this);

        var subscriptionId = $subscriptionUnPublisher.attr('data-subscription-id');

        $subscriptionUnPublisher.click(function(e) {
            e.preventDefault();

            var route = Routing.generate('subscription_unpublish', {'subscription': subscriptionId});
            $.ajax({
                url: route,
                success: function(data) {
                    if (data.unpublished) {
                        var $subscriptionSingle = $subscriptionUnPublisher.closest('.js-subscription-single');
                        
                        $subscriptionSingle.addClass('admin-subscription-single-unpublished');
                        $subscriptionSingle.find('.js-subscription-single-status').add();
                        $subscriptionUnPublisher.remove();

                        openPagePopUp($subscriptionUnPublishedPopup);
                        
                    }
                }
            });
        })
    });

    $subscriptionPopupFlashClose.click(function () {
        closePagePopup($subscriptionUnPublishedPopup);
        closePagePopup($subscriptionPublishedPopup);
        // window.location.reload();
    });

    $.each($subscriptionSessionSubscriptionIncluders, function() {
        var $subscriptionSessionSubscriptionIncluder = $(this);

        $subscriptionSessionSubscriptionIncluder.click(function(e) {
            e.preventDefault();

            var $subscriptionSessionSingle = $subscriptionSessionSubscriptionIncluder.closest('.js-subscription-single-session');
            var sessionSubscriptionId = $subscriptionSessionSingle.attr('data-session-subscription-id');

            var route = Routing.generate('session_subscription_reactivate', {'sessionSubscription': sessionSubscriptionId});

            $.ajax({
                url: route,
                success: function(data) {
                    if (data.reactivated) {
                        $subscriptionSessionSingle.removeClass('admin-subscription-single-session-excluded');
                        $subscriptionSessionSubscriptionIncluder.addClass('hidden');
                        $subscriptionSessionSingle.find('.js-subscription-session-subscription-excluder').removeClass('hidden');
                        $subscriptionSessionSingle.find('.js-subscription-session-subscription-excluded-status').addClass('hidden');
                    }
                }
            });
        });
    });

    $.each($subscriptionSessionSubscriptionExcluders, function() {
        var $subscriptionSessionSubscriptionExcluder = $(this);

        $subscriptionSessionSubscriptionExcluder.click(function(e) {
            e.preventDefault();

            var $subscriptionSessionSingle = $subscriptionSessionSubscriptionExcluder.closest('.js-subscription-single-session');
            var sessionSubscriptionId = $subscriptionSessionSingle.attr('data-session-subscription-id');

            var route = Routing.generate('session_subscription_deactivate', {'sessionSubscription': sessionSubscriptionId});

            $.ajax({
                url: route,
                success: function(data) {
                    if (data.deactivated) {
                        $subscriptionSessionSingle.addClass('admin-subscription-single-session-excluded');
                        $subscriptionSessionSubscriptionExcluder.addClass('hidden');
                        $subscriptionSessionSingle.find('.js-subscription-session-subscription-includer').removeClass('hidden');
                        $subscriptionSessionSingle.find('.js-subscription-session-subscription-excluded-status').removeClass('hidden');
                    }
                }
            });
        });
    });
});
