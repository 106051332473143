$(document).ready(function () {
    $sessionSearch = $('.js-session-search');

    if (0 === $sessionSearch.length) {
        return;
    }

    $sessionSearchForm = $sessionSearch.find('.js-session-search-form');
    $sessionSearchSubmitButton = $sessionSearch.find('.js-session-search-submit');
    $sessionSearchSubmitAlertButton = $sessionSearch.find('.js-session-search-submit-alert');
    $sessionSearchSubmissionTypeField = $sessionSearch.find('.js-session-search-submission-type');
    $sessionSearchResults = $sessionSearch.find('.js-session-search-results');
    $sessionSearchNoResultPopup = $sessionSearch.find('.js-session-search-no-result-popup');
    $sessionSearchAlertContainer = $sessionSearch.find('.js-session-search-alert-container');
    $sessionSearchAlertPopup = $sessionSearch.find('.js-session-search-alert-popup');
    $sessionSearchPopUpBackground = $sessionSearch.find('.js-session-search-popup-background');
    $sessionSearchFormSport = $sessionSearch.find('.js-session-search-form-sport');
    $sessionSearchFormAddress = $sessionSearch.find('.js-session-search-form-address');

    var openPagePopUp = function ($pagePopup) {
        $pagePopup.removeClass('hidden');
        $sessionSearchPopUpBackground.removeClass('hidden');
    };

    var closePagePopup = function ($pagePopup) {
        $pagePopup.addClass('hidden');
        $sessionSearchPopUpBackground.addClass('hidden');
    };

    $sessionSearch.on('click', '.js-session-search-popup-close', function () {
        var $sessionSearchClosestPopup = $(this).closest('.js-session-search-popup');

        closePagePopup($sessionSearchClosestPopup);
    });

    $sessionSearchAlertContainer.on('click', '.js-session-search-popup-close', function () {
        var $sessionSearchClosestPopup = $(this).closest('.js-session-search-popup');
        closePagePopup($sessionSearchClosestPopup);
    });

    $(document).keyup(function (event) {
        if (event.which == '27') {
            if (0 < $sessionSearchNoResultPopup.length) {
                closePagePopup($sessionSearchNoResultPopup);
            }

            if (0 < $sessionSearchAlertPopup.length) {
                closePagePopup($sessionSearchAlertPopup);
            }
        }
    });

    $sessionSearchSubmitButton.click(function (e) {
        e.preventDefault();

        $sessionSearchSubmissionTypeField.val('submit');
        $sessionSearchForm.attr('action', Routing.generate('session_search', {'is_alert_creation': $sessionSearchForm.attr('data-is-alert-creation') }));
        closePagePopup($sessionSearchNoResultPopup);
        $sessionSearchForm.submit();
    });

    $sessionSearchSubmitAlertButton.click(function (e) {
        e.preventDefault();

        $sessionSearchSubmissionTypeField.val('submit_alert');
        $sessionSearchForm.attr('action', Routing.generate('session_create_search_alert'));
        $sessionSearchForm.submit();
    });

    if ($sessionSearchForm[0].hasAttribute('data-trigger-infinite-scroll-change')) {
        extraSearchResultsData = $sessionSearchForm.serializeArray();
        var infiniteSrcollData = {'url': $sessionSearchForm.attr('action'), 'url_type': 'POST', 'data': extraSearchResultsData};

        var reEnableInfiniteScroll = true == $(window).data('ajaxready');

        $sessionSearchResults.trigger('infinite_scroll_disable');
        $sessionSearchResults.trigger('infinite_scroll_url_change', infiniteSrcollData);

        if (reEnableInfiniteScroll) {
            $sessionSearchResults.trigger('infinite_scroll_enable');
        }
    }

    $sessionSearchForm.on('submit', function (e) {
        e.preventDefault();

        extraSearchResultsData = $sessionSearchForm.serializeArray();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action') + '&offset=0',
            data: extraSearchResultsData,
            dataType: 'json',
            success: function (data) {
                if ('undefined' !== typeof data['search_alert_creation_popup']) {
                    $sessionSearchAlertContainer.html($.parseHTML(data['search_alert_creation_popup']));

                    $sessionSearchAlertPopup = $sessionSearchAlertContainer.find('.js-session-search-alert-popup');

                    if (0 < $sessionSearchAlertPopup.length) {
                        openPagePopUp($sessionSearchAlertPopup);
                    }

                    return;
                }

                $sessionSearchResults.html($.parseHTML(data['extra_results_view']));

                if (0 < data['extra_results_count']) {
                    $sessionSearchForm.trigger('infinite_scroll_enable');
                } else {
                    $sessionSearchForm.trigger('infinite_scroll_disable');
                }

                var infiniteSrcollData = {'url': $sessionSearchForm.attr('action'), 'url_type': 'POST', 'data': extraSearchResultsData};
                $sessionSearchResults.trigger('infinite_scroll_url_change', infiniteSrcollData);

                $('html, body').animate({
                    scrollTop: $sessionSearchResults.offset().top
                }, 700);

                if (0 === data['extra_results_count']) {
                    openPagePopUp($sessionSearchNoResultPopup);
                }
            }
        });
    });

    $sessionSearchResults.on('click', '.js-session-search-create-alert-popup', function (e) {
        e.preventDefault();

        closePagePopup($sessionSearchNoResultPopup);

        $sessionSearchSubmitAlertButton.click();
    });

    if (0 < $sessionSearchNoResultPopup.length && $sessionSearchNoResultPopup.hasClass('js-session-search-no-result-popup-auto')) {
        openPagePopUp($sessionSearchNoResultPopup);
    }

    if ($sessionSearchFormSport.val() != '' && $sessionSearchFormAddress.val() != '') {
        $sessionSearchForm.submit();
    } else if (0 < $sessionSearchResults.find('.js-session-search-single-result').length) {
        $('html, body').animate({
            scrollTop: $sessionSearchResults.offset().top
        }, 700);
    }
});
