$(document).ready(function() {
    var $newsFeedForm = $('.js-news-feed-form');

    if (0 === $newsFeedForm.length) {
        return;
    }

    var $newsFeedFormContent = $('.js-news-feed-form-content');

    window.textIsRaw = false;

    $newsFeedForm.submit(function(e) {
        if (true === window.textIsRaw) {
            return true;
        }

        e.preventDefault();

        var replacementContent = $newsFeedFormContent.val().replace(/<a>\s*(.[^\s]*)\s*<\/a>/g, '<a href="$1">$1</a>');
        $newsFeedFormContent.val(replacementContent);
        window.textIsRaw = true;
        $(this).submit();
    })
});
