$(document).ready(function() {
	////////////////////////////////////OUVERTURE/FERMETURE MENU (<1024px)
	$('#Open-Menu').click(function() {
		$('header nav > ul').css('right', '0');
	});

	$('#Close-Menu').click(function() {
		$('header nav > ul').css('right', '-100%');
	});
});
