$(document).ready(function() {
    var $notificationLink = $('.js-notification-link');

    if (0 === $notificationLink.length) {
        return;
    }

    $.ajax({
        type: 'GET',
        url: Routing.generate('count_new_notifications'),
        success: function (data) {
            if (0 < data.count_new_notifications) {
                $notificationLinkBadge = $notificationLink.find('.js-notification-link-badge');

                if (9 < data.count_new_notifications) {
                    $notificationLinkBadge.text('9+');
                } else {
                    $notificationLinkBadge.text(data.count_new_notifications);
                }

                $notificationLinkBadge.removeClass('hidden');
            }
        }
    });
});
