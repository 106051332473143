$(document).ready(function() {
    var $memberChoice = $('.js-member-choice');

    if (0 === $memberChoice.length) {
        return;
    }

    var formatMemberSelection = function (member) {
        if (member.firstname != undefined) {
            return member.firstname + ' ' + member.lastname;
        }

        return member.text;
    };

    var formatMember  = function(member) {
        if (member.email != undefined) {
            var markup = "<div class='select2-result-member'>" +
                "<div class='select2-result-member__title'>" + member.firstname + ' ' + member.lastname + "</div>" +
                "<div style='font-size: 12px'>(" + member.email +")" +
                "</div>";

            return markup;
        }
    };

    var route = $memberChoice.attr('data-route');

    var select2Parameters = {
        dropdownAutoWidth: true,
        width: 'style',
        ajax: {
            url: Routing.generate(route),
            cache: true,
            dataType: 'json',
            delay: 250,
            placeholder: 'Nom, prénom ou email du membre',
            allowClear: true,
            data: function (params) {
                return {terms: params.term};
            },
            processResults: function (data) {
                return {
                    results: data
                };
            }
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        multiple: true,
        maximumSelectionLength: 1,
        minimumInputLength: 2,
        templateResult: formatMember,
        templateSelection: formatMemberSelection,
        language: {
            noResults: function() {
                return 'Utilisateur inconnu';
            }
        }
    };

    $memberChoice.select2(select2Parameters);
});
