$(document).ready(function() {
    var $datePickerContainers = $('.js-datepicker');

    if (0 === $datePickerContainers.length) {
        return;
    }

    var datePickerOptions = {
        inline: true,
        closeText: 'Fermer',
        prevText: 'Précédent',
        nextText: 'Suivant',
        currentText: 'Aujourd\'hui',
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        weekHeader: 'Sem.',
        minDate: 0,
        maxDate: '+2Y',
        dateFormat: 'dd/mm/yy'
    };

    //nadjib
    var datePickerOptionsEnd = {
        inline: true,
        closeText: 'Fermer',
        prevText: 'Précédent',
        nextText: 'Suivant',
        currentText: 'Aujourd\'hui',
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        weekHeader: 'Sem.',
        minDate: 0,
        maxDate: '+2Y',
        dateFormat: 'dd/mm/yy'
    };

    $.each($datePickerContainers, function() {
        var $datePickerContainer = $(this);
        var startDateOptions = datePickerOptions;
        var endDateOptions = datePickerOptionsEnd;//nadjib
        var $datePickerStart = $datePickerContainer.find('.js-datepicker-start');
        var $datePickerEnd = $datePickerContainer.find('.js-datepicker-end');

        if (0 < $datePickerEnd.length) {
            startDateOptions.onSelect = function () {
                if ($datePickerEnd[0].hasAttribute('disabled')) {
                    return;
                }

                if ($datePickerEnd[0].hasAttribute('disabled-automatic-value')) {
                    return;
                }

                var minimumEndDate = $datePickerStart.datepicker('getDate');
                minimumEndDate.setDate(minimumEndDate.getDate() + 1);
                $datePickerEnd.datepicker('option', 'minDate', minimumEndDate);
                $datePickerEnd.datepicker('setDate', minimumEndDate);
            }
        }

        if ($datePickerStart[0].hasAttribute('data-allow-passed-dates')) {
            startDateOptions.minDate = '-1Y';
        }

        $datePickerStart.datepicker(startDateOptions);

        if (0 < $datePickerEnd.length) {
            $datePickerEnd.datepicker(endDateOptions);
        }
    });
});
