$(document).ready(function() {
    $displayHiddenTrigger = $('.js-display-hidden-trigger');

    if (0 === $displayHiddenTrigger.length) {
        return;
    }

    $displayHiddenTrigger.click(function(e) {
        e.preventDefault();
        $(this).addClass('hidden');
        var targetId = $(this).attr('data-target');

        $(targetId).removeClass('hidden');
    });
});
