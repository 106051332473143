$(document).ready(function() {
    var $linkDisabling = $('.js-link-disabling');

    if (0 === $linkDisabling.length) {
        return;
    }

    var $linkDisablingPopup = $('.js-link-disabling-login-popup');
    var $linkDisablingPopupClose = $('.js-link-disabling-popup-close');
    var $linkDisablingPopupBackground = $('.js-link-disabling-popup-background');

    var openPagePopUp = function () {
        $linkDisablingPopup.removeClass('hidden');
        $linkDisablingPopupBackground.removeClass('hidden');
    };

    var closePagePopup = function () {
        $linkDisablingPopup.addClass('hidden');
        $linkDisablingPopupBackground.addClass('hidden');
    };

    $linkDisabling.on('click', 'a, button', function(e) {
        $clickedElement = $(e.target);

        if (0 < $clickedElement.closest('.js-link-disabling-excluded').length) {
            return;
        }

        //This condition covers external modals like the Hubspot ones
        if (0 < $clickedElement.closest('[aria-modal="true"]').length) {
            return;
        }

        e.preventDefault();

        openPagePopUp();
    });

    $(document).keyup(function (event) {
        if (event.which == '27') {
            closePagePopup();
        }
    });

    $linkDisablingPopupClose.click(function () {
        closePagePopup();
    });
});
